(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("vizceral"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "vizceral"], factory);
	else if(typeof exports === 'object')
		exports["Vizceral"] = factory(require("react"), require("vizceral"));
	else
		root["Vizceral"] = factory(root["React"], root["vizceral"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__) {
return 